import { Link } from "gatsby"
import React from "react"

import Navigation from "../navigation/index"

import * as styles from  "./header.module.scss"
import Logo from "./logo_dmart_black.svg"

const Header = ({ siteTitle, navigation }) => (
  <header className={styles.siteHeader}>
    <div className="container">
      <div className="row">
        <div className="column-left">
          <h1>
            <Link className={styles.siteLogo} to="/">
              <Logo alt={siteTitle} />
            </Link>
          </h1>
        </div>
        <div className="column-right">
          <Navigation navigation={navigation} />
        </div>
      </div>
    </div>
  </header>
)

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
