
import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import * as styles from "./navigation.module.scss"

const ComponentName = () => {
  const data = useStaticQuery(graphql`
    {
      wpMenu(name: {eq: "Header Menu"}) {
        name
        locations
        menuItems {
          nodes {
            id
            label
            path
            cssClasses
          }
        }
      }
    }
  `)
  return (
    <div className={styles.navigation}>
      {
        data.wpMenu.menuItems.nodes.map(function (item) {
          if (item.path === "/"){
            return (
              <Link
                className={styles.navigationItem}
                activeClassName={styles.navigationItemActive}
                to={`${item.path}`}
                key={item.id}>
                {item.label}
              </Link>
            )
          } else {
            return (
              <Link
                partiallyActive={true}
                className={styles.navigationItem}
                activeClassName={styles.navigationItemActive}
                to={`${item.path}`}
                key={item.id}>
                {item.label}
              </Link>
            )
          }
        })
      }
    </div>
    )
}

export default ComponentName

