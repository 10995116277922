import React from "react"

import * as styles from "./footer.module.scss"

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className="row">
          <div className="column-left">
            copyright dmart.ch
            <br />
            web architect: <a href="https://minguely.ch">@jminguely</a>
          </div>
          <div className="column-right">
            <div className={styles.footerColumns}>
              <div>
                atelier
                <br />
                d’architecture
                <br />
                DMart sàrl
              </div>
              <div>
                <a href="https://goo.gl/maps/ZbHY7xDS65ofG4nYA" target="_blank" without rel="noreferrer">
                  <address>
                    rue du château 35
                    <br />
                    case postale 23
                    <br />
                    1564 domdidier
                  </address>
                </a>
              </div>
              <div>
                <a href="tel:+41 26 675 32 90">+41 26 675 32 90</a>
                <br />
                <a href="mailto:info@dmart.ch">info@dmart.ch</a>
              </div>
              <div>
                <a href="https://www.facebook.com/dmart.ch" target="_blank" rel="noreferrer">facebook</a>
                <br />
                <a href="https://www.instagram.com/dmart_sarl" target="_blank" rel="noreferrer">instagram</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
