/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"

import Header from "../header"
import Footer from "../footer"
import * as styles from './layout.module.scss'

const Layout = ({ children, featuredImage, setScrollBelowContent }) => {
  const mainContentRef = React.createRef();

  const [scrollTop] = useState(0);

  const data = useStaticQuery(graphql`
    query {
      wp {
        generalSettings {
          title
          url
        }
      }
      pages: allWpPage(sort: {order: ASC, fields: menuOrder}) {
        edges {
          node {
            id
            title
            slug
          }
        }
      }
    }
  `)

  useEffect(() => {
    const onScroll = e => {
      if (mainContentRef.current && setScrollBelowContent) {
        if (window.scrollY > mainContentRef.current.offsetTop) {
          setScrollBelowContent(true);
        } else {
          setScrollBelowContent(false);
        }
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop, mainContentRef, setScrollBelowContent]);

  return (
    <>
      <div className={styles.grid}>
        <div className={styles.layoutHeader}>
          <Header siteTitle={data.wp.generalSettings.title} navigation={data.pages.edges} />
        </div>
        <main className={styles.layoutMain}>
          {
            featuredImage && (
              <div className={styles.featuredImage}>
                <img className={`${featuredImage.node.imageCustomFields ? `object-position-${featuredImage.node.imageCustomFields.position}`: 'object-position-center'}`} src={featuredImage.node.localFile.publicURL} alt={featuredImage.altText} />
              </div>
            )
          }
          <div ref={mainContentRef} >
            {children}
          </div>
        </main>

        <div className={styles.layoutFooter}>
          <Footer />
        </div>
      </div>
    </>
  )
}


export default Layout
